@media all and (max-width:480px) {
    .btn-xs { 
      padding-top: 5px;
      padding-right: 15px;
      padding-bottom: 5px;
      padding-left: 15px;
      white-space: normal;
    }
    .btn-xs-0 { 
      margin-left: 0px !important;
    }
    .icon-xs { 
      margin-left: 0px !important;
      margin-right: 5px !important;
    }
    .icon-xs-0 { 
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
    .display-none-xs {
      display: none !important;
    }
    .content-xs {
      padding-left: 2px !important;
      padding-right: 2px !important;
    }
    .custom-dialog-xs {
      padding: 5px !important;
      font-size: 12px !important;
    }
} 

.custom-calendar {
  width: 100%;
}

.custom-logo-menu {
  margin-top: 0px !important; 
  margin-bottom: 0px !important; 
  margin-left: 0px !important;
  margin-right: 10px !important;
  padding-top: 0px !important; 
  padding-bottom: 0px !important; 
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 30% !important;
}

.custom-dialog {
  background-color: #1e1e2f;
  border-radius: 10px;
  padding: 15px;
  font-size: 0.875rem;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.custom-input-color {
  width: 100% !important;
  height: calc(2px + 2.25rem) !important;
  background-color: inherit !important;
  border-color: #2b3553 !important;
  border-radius: 0.4285rem !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.div-scroll {
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
}

body.react-confirm-alert-body-element {
  overflow: hidden;
}
  
.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 30000;
  background: transparent;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  text-align: right;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 10px;
  text-align: left;
  background: linear-gradient(0deg, #3358f4 0%, #1d8cf8 100%);
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13); 
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: red;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

.text-area-notice {
  padding-top: 5px !important;
  padding-right: 10px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  line-height: 1.5 !important;
  margin-bottom: 10px !important;
  margin-top: 5px !important;
  max-height: 100% !important;
  resize: auto !important;
  font-size: 15pt !important;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

